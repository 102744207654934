import axios from 'axios'

const axiosInstance = {
    auth: axios.create({
        baseURL: process.env.REACT_APP_AWS_MCP_API_AUTH,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY_AUTH
        },
        withCredentials: true
    }),
    mipad: axios.create({
        baseURL: process.env.REACT_APP_AWS_MCP_API_MIPAD,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY_MIPAD
        },
        withCredentials: true
    })
}

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthGetRequests(query: string): Promise<any> {
    const response = await axiosInstance.auth.get(query);
    return response.data;
}

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthPostRequests(query: string, body?: any): Promise<any> {
    const response = await axiosInstance.auth.post(query, body);
    return response.data;
}

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiGetRequests(query: string): Promise<any> {
    const response = await axiosInstance.mipad.get(query);
    return response.data;
}

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiPostRequests(query: string, body?: any): Promise<any> {
    const response = await axiosInstance.mipad.post(query, body);
    return response.data;
}