import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store/store";
import {signOut} from "../store/user/userFunction";
import {Box, CircularProgress, Container, Paper} from "@mui/material";
import {Page} from "../layouts/components";

// User Logout Function
export default function Logout() {
    const dispatch = useAppDispatch();
    const authenticated = useAppSelector(state => state.user.authenticated)

    // reroutes User back to auth App when signed out
    useEffect(() => {
        if (!authenticated) {
            window.location.href = `${process.env.REACT_APP_AUTH_APP}`;
        }
    }, [authenticated])

    // Signs out user
    useEffect(() => {
        dispatch(signOut())
    }, [dispatch])

    return (
        <Page title='MiPad - Login'>
            <Container
                maxWidth='sm'
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Paper
                    sx={{
                        bgcolor: 'grey.100',
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // minHeight: '80vh',
                        m: 2,
                        p: 2,
                        pb: 20,
                        height: '700px'
                    }}
                    elevation={10}
                >
                    <Box sx={{
                        textAlign: 'center',
                        p: 2,
                        width: '100%',
                        height: '100%',
                    }}>
                        <Box
                            sx={{
                                p: 2,
                                pb: 10,
                                width: '50%'
                            }}
                            component='img'
                            src='/static/stacked_metrics.png'
                            alt='Metrics'
                        />
                        <CircularProgress/>
                    </Box>
                </Paper>

            </Container>
        </Page>
    )
}