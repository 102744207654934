import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
// REDUX

// Checks user if authenticated, if authenticated passes to home.
export function AuthenticationCheck({authenticated, children}: {authenticated: boolean, children: JSX.Element}) {
    // const location = useLocation();
    const navigate = useNavigate();

    // Check if Active user session
    // If user in state reroute to page
    useEffect(() => {
        if (authenticated) {
            navigate('/app/home', {replace: true})
        }
    }, [authenticated, navigate])

    // Default Navigate to unauthenticated
    return children;
}


// Checks user if authenticated grant access to children otherwise redirect back to /
export function RequireAuth({authenticated, children}: { authenticated: boolean, children: JSX.Element }) {
    const navigate = useNavigate();

    // If no user in state
    useEffect(() => {
        if (!authenticated) {
            navigate('/')
        }
    }, [authenticated, navigate])

    // Default route to authenticated page
    return children;
}