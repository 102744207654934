// @mui
import {Container, Grid, Typography} from '@mui/material';
import {
    AccountBalanceOutlined as AccountBalanceIcon,
    Assessment as AssessmentIcon,
    AttachMoneyTwoTone as AttachMoneyIcon,
    LibraryBooksTwoTone as BookIcon
} from '@mui/icons-material'
// components
import {AppCard} from "../../components";
import {Page} from '../../layouts/components';
// REDUX STORE
import {useAppSelector} from "../../store/store";
import {getAppsPermissions} from "../../store/user/userSelector";

// ----------------------------------------------------------------------

// Default Page for Unfound
export default function Main() {

    const name = useAppSelector(state => state.user.user?.givenName);
    const appAccess = useAppSelector(getAppsPermissions)

    return (
        <Page title="Dashboard">
            <Container sx={{m: 10, maxWidth: 1500}} maxWidth={false}>
                <Typography variant="h3" paragraph align="left">
                    Welcome {name}!
                </Typography>
                <Grid container padding={2}>
                    {appAccess['asset_register'] &&
                        <AppCard
                            title="Asset Fund Register"
                            icon={<BookIcon/>}
                            routeTo={process.env.REACT_APP_MCP_AFR || '/'}
                        />
                    }
                    {appAccess['capital_budget'] &&
                        <AppCard
                            title="Capital Budget"
                            icon={<AccountBalanceIcon/>}
                            routeTo={process.env.REACT_APP_MCP_CB || '/'}
                        />
                    }
                    {appAccess['power_bi'] &&
                        <AppCard
                            title="Power BI Reports"
                            icon={<AssessmentIcon/>}
                            routeTo={process.env.REACT_APP_MCP_BI || '/'}
                        />
                    }
                    {appAccess['valuation_model'] &&
                        <AppCard
                            title="Valuation Model"
                            icon={<AttachMoneyIcon/>}
                            routeTo={process.env.REACT_APP_MCP_VM || '/'}
                        />
                    }
                </Grid>
            </Container>
        </Page>
    );
}
