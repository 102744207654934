import React, {ReactElement, useState} from "react";
import {Link as RouterLink} from 'react-router-dom';
// @MUI
import {Button, Grid, Stack, Paper, Typography} from "@mui/material";
import {
    ArrowForward as ArrowIcon,
} from "@mui/icons-material";

// Shows available application to route to
function AppCard({title, routeTo, icon}: { title: string, routeTo: string, icon: ReactElement }) {
    const [hovered, setIsHovered] = useState<boolean>(false);

    return (
        <Grid
            item
            sm={12}
            md={6}
            lg={4}
        >
            <Paper
                elevation={7}
                sx={{
                    m: 2,
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Button
                    size='large'
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'left',
                        border: 'none',
                        p: 0
                    }}
                    to={routeTo}
                    component={RouterLink}
                >
                    <Stack direction="row"
                           sx={{
                               display: 'flex',
                               justifyContent: 'flex-start',
                               alignContents: 'left',
                               alignItems: 'center',
                               width: '100%',
                               p: 2
                           }}
                           spacing={4}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignContents: 'left',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {React.cloneElement(icon, {sx: {fontSize: 75, border: 1, borderRadius: 1, p: 2}})}
                            <Typography variant='h5' sx={{pl: 2}}>
                                {title}
                            </Typography>
                        </Stack>
                        {hovered &&
                        <ArrowIcon
                            sx={{
                                fontSize: 30,
                            }}
                        />
                        }
                    </Stack>

                </Button>
            </Paper>
        </Grid>
    )
}

export default AppCard;