import {MouseEvent, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
// @MUI
import {Avatar, Box, Divider, IconButton, MenuItem, Typography} from "@mui/material";
import {useAppSelector} from "../../store/store";
// Types
import {User} from "../../types/UserTypes";
// components
import {MenuPopover} from "./";

// Display and Profile Handler for user.
function Profile() {
    const navigate = useNavigate();

    const user: User | null = useAppSelector(state => state.user.user)

    const anchorRef = useRef(null);

    const [open, setOpen] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <Box sx={{ width: 45}}>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    // p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                        },
                    }),
                }}
            >

                {user && user.profile ?
                    <Avatar
                        sx={{
                            bgcolor: 'primary.main',
                            border: 1,
                            BorderColor: 'common.white'
                        }}
                        src={`data:image/jpeg;base64, ${user.profile}`}
                    />
                :
                    <Avatar
                        sx={{
                            bgcolor: 'primary.main',
                            border: 1,
                            BorderColor: 'common.white'
                        }}
                    >
                        {user ? `${user.givenName.charAt(0).toUpperCase()}${user.surname.charAt(0).toUpperCase()}` : ''}
                    </Avatar>
                }

            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ py: 1.5, px: 2.5 , bgcolor: 'secondary.main'}}>
                    <Typography variant="subtitle2" noWrap sx={{color: 'common.white'}}>
                        {user?.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.mail}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                {/*<MenuItem onClick={() => navigate('/dashboard/profile')} sx={{ m: 1 }}>*/}
                {/*    Profile*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={() => navigate('/logout')} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </Box>
    )

}

export default Profile;